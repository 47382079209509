// extracted by mini-css-extract-plugin
export var contactList = "contact-module--contactList--wx-NI";
export var contactLogo = "contact-module--contactLogo--IDICh";
export var containerWrapper = "contact-module--containerWrapper--Exouq";
export var infoContainer = "contact-module--infoContainer--eI1LN";
export var infoWrapper = "contact-module--infoWrapper--R94Yu";
export var linkContainer = "contact-module--linkContainer--drP0W";
export var mainContainer = "contact-module--mainContainer--XbZMb";
export var sectionWrapper = "contact-module--sectionWrapper--JGNZF";
export var text = "contact-module--text--094JI";
export var title = "contact-module--title--imJig";